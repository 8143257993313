import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { setDataRecord, updateUser } from 'imports/carrer/api/api';
import {
  getCountryCode,
  getCurrencyFormat,
  getJobTitle,
  isWithCareerLead,
  LOCALES_WITH_CAREER_EXP,
} from 'imports/carrer/api/helper';
import Tab4 from 'imports/carrer/components/Tab4';
import { getCountry } from 'imports/checkout/api/utils';
import { replaceString } from 'imports/core/ui/helpers';
import { withRouter } from 'next/router';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';
import styled, { css } from 'styled-components';
import Tab2Sub from '/imports/carrer/components/Tab2Sub';
import Tab3 from '/imports/carrer/components/Tab3';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withGlobalContext } from '/imports/core/api/global.context';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import intlHook, { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';
import WizardHeader from '/imports/generator/ui/atoms/WizardHeader';
import GeneratorForm from '/imports/generator/ui/components/GeneratorForm';
import ResumeScorePage from '/imports/generator/ui/components/ResumeScorePage';
import WizardLayout from '/imports/generator/ui/layouts/WizardLayout';
import { getReMarketingConv, isRemarketingHost, removeExperiment } from '/lib/helpers';
import { isRwLeadAlreadyCreated, rwCreateLeadStore } from 'imports/checkout/api/helpers';
import env from '/env';

@withTracking
@withIntl
@withRouter
@withResponsiveContext
@withAccount
@withGlobalContext
class WizardFinalize extends PureComponent {
  static propTypes = {
    resume: PropTypes.object,
    currentUser: PropTypes.object,
  };

  state = {
    show: false,
    popshown: false,
    tab: 0,
    field: { professionHelpServices: [], amountSpendForCareer: '', contactToExpert: '' },
    userData: null,
    progress: null,
    showWritingFeature: false,
  };
  componentDidMount() {
    const {
      trackEvent,
      host,
      currentUser,
      context: {
        state: { userData },
      },
      locale,
      isMobile,
      resume,
    } = this.props;
    const { token } = nookies.get({});
    const stateFlag = typeof localStorage !== 'undefined' && localStorage.getItem('flag');
    const thirdFlag = stateFlag && JSON.parse(stateFlag);

    if (!currentUser?.disableResumeReview) {
      this.setState({
        showWritingFeature: true,
      });
    }
    if (!thirdFlag?.thirdStep && (userData ? !userData.disableCarrerQuiz : !currentUser.disableCarrerQuiz)) {
      this.setState({
        show: true,
      });
    }
    localStorage.setItem('resumedone:finalize-seen', 'true');
    localStorage.setItem('resumedone:from-finalize', 'true');
    if (
      !marvelEmitter.getActiveVariant('exp_career_status_v2') ||
      !LOCALES_WITH_CAREER_EXP.includes(locale) ||
      marvelEmitter.getActiveVariant('exp_career_status_v2') === 'control' ||
      marvelEmitter.getActiveVariant('exp_career_status_v3') === 'control' ||
      (userData ? userData.disableCarrerQuiz : currentUser.disableCarrerQuiz)
    ) {
      let obj = {};
      if (marvelEmitter.getActiveVariant('block_respositing_step_exp')) {
        obj.block_respositing_step_exp = marvelEmitter.getActiveVariant('block_respositing_step_exp');
      }
      const autoExpandExp = marvelEmitter.getActiveVariant('exp_auto_expand_description_mobile');
      if (autoExpandExp) {
        obj.exp_auto_expand_description_mobile = autoExpandExp;
      }
      if (marvelEmitter.getActiveVariant('exp_template_budapest_v2')) {
        obj.exp_template_budapest_v2 = marvelEmitter.getActiveVariant('exp_template_budapest_v2');
      }

      const { resume } = this.props;
      if (marvelEmitter.getActiveVariant('exp_scoring_plugin_v2')) {
        obj.exp_scoring_plugin_v2 = marvelEmitter.getActiveVariant('exp_scoring_plugin_v2');
      }
      if (marvelEmitter.getActiveVariant('exp_rename_section_title')) {
        obj.exp_rename_section_title = marvelEmitter.getActiveVariant('exp_rename_section_title');
      }
      if (marvelEmitter.getActiveVariant('exp_social_link_logos')) {
        obj.exp_social_link_logos = marvelEmitter.getActiveVariant('exp_social_link_logos');
      }
      if (marvelEmitter.getActiveVariant('exp_brunei_option')) {
        obj.exp_brunei_option = marvelEmitter.getActiveVariant('exp_brunei_option');
      }

      obj.resume_language = resume.settings.language;
      trackEvent('finalize_view', obj);
    }

    removeExperiment('finalize_language_experiment');
    removeExperiment('column_switch_experiment');
    removeExperiment('exp_add_blocks_icons');
    removeExperiment('exp_undo_redo_textarea_editor');
    removeExperiment('exp_chat_bot');
    removeExperiment('exp_chat_bot_v2');
    removeExperiment('exp_chat_bot_v3');

    if (window && window.gtag && isRemarketingHost(host)) {
      const remarketingData = getReMarketingConv(host);
      //eslint-disable-next-line
      gtag('event', 'conversion', {
        send_to: `${remarketingData.id}/${remarketingData.finalize}`,
        aw_remarketing_only: true,
      });
    }
    if (['bluepill'].includes(host)) this.createAutoApplyLead();
  }

  createAutoApplyLead = async () => {
    const { currentUser, resume, locale } = this.props;
    const { token } = nookies.get({});
    if (isRwLeadAlreadyCreated()) return;
    const leadPayload = {
      id: currentUser.id,
      lang: locale,
      resumeUrl: `https://resumedone.co/user-resume/${currentUser.id}/${resume.id}`,
      leadType: 'resumeDownloads',
      isTestEnv: env.NODE_ENV === 'production' ? false : true,
      leadLanguage: locale,
      resumeLanguage: resume.settings.language,
    };
    await setDataRecord(leadPayload, token);
    rwCreateLeadStore();
  };

  handleClick = (key, value) => () => {
    const { field } = this.state;
    const isValueExist = field.professionHelpServices.includes(value);
    if (isValueExist) {
      this.setState((prevState) => ({
        field: {
          ...prevState.field,
          professionHelpServices: prevState.field.professionHelpServices.filter((item) => item !== value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        field: {
          ...prevState.field,
          professionHelpServices: [...prevState.field.professionHelpServices, value],
        },
      }));
    }
  };

  handleData = async (key, value) => {
    const { refetch } = this.props;
    const { token } = nookies.get({});
    const resp = await updateUser({ [key]: value }, token);
    if (key === 'contactExpert') refetch();
    this.setState({
      userData: resp,
    });
    this.props.context.handleUpdateState('userData', resp);
  };

  handleRadioClick = (key, value) => () => {
    this.setState(
      (prevState) => {
        return {
          field: {
            ...prevState.field,
            [key]: value,
          },
        };
      },
      () => {
        let tempKey = key;
        if (tempKey === 'contactToExpert') tempKey = 'contactExpert';
        this.handleData(tempKey, value);
        this.handleClose();
      },
    );
  };

  handleBack = () => {
    const { tab } = this.state;
    if (tab === 0) return;
    this.setState((prevState) => ({
      tab: prevState.tab - 1,
    }));
  };

  getServiceName = (service) => {
    switch (service) {
      case 'Yes, to assist me in my job search.':
        return 'jobSearch';
      case 'Yes, to take stock of my career.':
        return 'stockCareer';
      case 'Yes, to proofread and optimize my CV.':
        return 'proofread';
      case 'Yes, to prepare for job interviews.':
        return 'prepareInterview';
    }
  };

  handleServices = (services) => {
    if (services.length === 0) return null;
    let data = [];
    services.forEach((element) => {
      data.push({ [this.getServiceName(element)]: true });
    });
    return data;
  };

  handleSavingData = () => {
    const { field, progress } = this.state;
    const { trackEvent, currentUser, resume, locale } = this.props;
    const { token } = nookies.get({});
    const isWithVideo = marvelEmitter.getActiveVariant('exp_career_video') === 'with_video';

    let trackObj = {
      contactExpert: field.contactToExpert,
    };
    if (isWithVideo) trackObj.videoProgress = Math.floor(progress);
    this.handleData('contactExpert', field.contactToExpert);
    const obj = {
      id: currentUser.id,
      lang: locale,
      resumeUrl: `https://resumedone.co/user-resume/${currentUser.id}/${resume.id}`,
      lastJobTitle: getJobTitle(resume),
      variant: 'without_first_step_flow',
      isTestEnv: env.NODE_ENV === 'production' ? false : true,
      leadLanguage: locale,
      resumeLanguage: resume?.settings?.language || '',
    };
    trackEvent('contactExpert', trackObj);
    setTimeout(() => {
      setDataRecord(obj, token);
    }, 500);
  };

  handleClose = () => {
    const { tab, field } = this.state;
    const { trackEvent, isMobile, locale } = this.props;
    const funnelVariant = marvelEmitter.getActiveVariant('click_to_edit_funnel_exp');
    const autoExpandExp = marvelEmitter.getActiveVariant('exp_auto_expand_description_mobile');
    if (
      field.professionHelpServices.length === 0 ||
      field.professionHelpServices.includes('None') ||
      field.amountSpendForCareer === `${getCurrencyFormat(0)} - ${getCurrencyFormat(50)}` ||
      !!field.contactToExpert
    ) {
      if (!!field.contactToExpert) this.handleSavingData();
      this.setState({
        show: false,
      });
      this.handleData('disableCarrerQuiz', true);
      const scolledDiv = document.getElementById('scroll-cont');
      scolledDiv.scrollTo(0, 0);
      let obj = {};
      if (marvelEmitter.getActiveVariant('block_respositing_step_exp')) {
        obj.block_respositing_step_exp = marvelEmitter.getActiveVariant('block_respositing_step_exp');
      }
      if (autoExpandExp) {
        obj.exp_auto_expand_description_mobile = autoExpandExp;
      }

      const { resume } = this.props;
      obj.resume_language = resume.settings.language;
      if (marvelEmitter.getActiveVariant('exp_scoring_plugin_v2')) {
        obj.exp_scoring_plugin_v2 = marvelEmitter.getActiveVariant('exp_scoring_plugin_v2');
      }
      if (marvelEmitter.getActiveVariant('exp_rename_section_title')) {
        obj.exp_rename_section_title = marvelEmitter.getActiveVariant('exp_rename_section_title');
      }

      if (marvelEmitter.getActiveVariant('exp_bullet_point_setup_in_text_field')) {
        obj.exp_bullet_point_setup_in_text_field = marvelEmitter.getActiveVariant(
          'exp_bullet_point_setup_in_text_field',
        );
      }
      if (marvelEmitter.getActiveVariant('exp_brunei_option')) {
        obj.exp_brunei_option = marvelEmitter.getActiveVariant('exp_brunei_option');
      }
      if (field.professionHelpServices.includes('None')) trackEvent('professionHelpServices', { none: true });

      trackEvent('finalize_view', obj);
      localStorage.setItem('flag', JSON.stringify({ firstStep: 'true', secondStep: 'true', thirdStep: 'true' }));
      return;
    }
    if (tab === 1 && field.amountSpendForCareer !== '') {
      if (field.amountSpendForCareer)
        trackEvent('amountSpendForCareer', { amountSpendForCareer: field.amountSpendForCareer });
    }
    if (tab === 2) {
      this.setState({
        show: false,
        popshown: true,
      });
      const scolledDiv = document.getElementById('scroll-cont');
      scolledDiv.scrollTo(0, 0);
      let obj = {};
      if (marvelEmitter.getActiveVariant('block_respositing_step_exp')) {
        obj.block_respositing_step_exp = marvelEmitter.getActiveVariant('block_respositing_step_exp');
      }
      if (autoExpandExp) {
        obj.exp_auto_expand_description_mobile = autoExpandExp;
      }

      const { resume } = this.props;
      obj.resume_language = resume.settings.language;
      if (marvelEmitter.getActiveVariant('exp_scoring_plugin_v2')) {
        obj.exp_scoring_plugin_v2 = marvelEmitter.getActiveVariant('exp_scoring_plugin_v2');
      }
      if (marvelEmitter.getActiveVariant('exp_rename_section_title')) {
        obj.exp_rename_section_title = marvelEmitter.getActiveVariant('exp_rename_section_title');
      }

      if (marvelEmitter.getActiveVariant('exp_bullet_point_setup_in_text_field')) {
        obj.exp_bullet_point_setup_in_text_field = marvelEmitter.getActiveVariant(
          'exp_bullet_point_setup_in_text_field',
        );
      }
      if (marvelEmitter.getActiveVariant('exp_brunei_option')) {
        obj.exp_brunei_option = marvelEmitter.getActiveVariant('exp_brunei_option');
      }

      trackEvent('finalize_view', obj);
      localStorage.setItem('flag', JSON.stringify({ firstStep: 'true', secondStep: 'true', thirdStep: 'true' }));
      return;
    }
    if (tab === 0) {
      if (field.professionHelpServices.length > 0) {
        this.handleData('professionHelpServices', field.professionHelpServices);
        const data = this.handleServices(field.professionHelpServices);
        let obj = {};
        data.forEach((k) => {
          obj = { ...obj, ...k };
        });
        trackEvent('professionHelpServices', obj);
      }
    }

    this.setState((prevState) => ({
      tab: prevState.tab + 1,
    }));
  };

  handleProgress = (state) => {
    this.setState({
      progress: state.playedSeconds,
    });
  };

  handleCloseWritingPage = () => {
    const { refetch } = this.props;
    this.setState({
      showWritingFeature: false,
    });
    refetch();
  };

  render() {
    const { resume, updateImmue, referenceResume, locale, isMobile, trackEvent } = this.props;
    const country = getCountry();
    const activeVar = marvelEmitter.getActiveVariant('exp_career_status_v2');
    const withNewFlow =
      ['without_first_step_flow'].includes(activeVar) ||
      isWithCareerLead(locale, country) ||
      ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v3')) ||
      ['with_career_lead'].includes(marvelEmitter.getActiveVariant('exp_lead_generation_v2'));
    const lastTab = withNewFlow;
    const withScorePage = marvelEmitter.getActiveVariant('exp_resumes_writing') === 'with_resume_writing';
    const { showWritingFeature } = this.state;
    return (
      <WizardLayout
        resume={resume}
        finalize
        updateImmue={updateImmue}
        popshown={this.state.popshown}
        handleClose={this.handleClose}
        show={this.state.show}
        tab={this.state.tab}
        isLastTab={lastTab ? this.state.tab === 1 : this.state.tab === 2}
        handleBack={this.handleBack}
      >
        {withNewFlow && this.state.show ? (
          <RenderCareerAbtest
            resume={resume}
            hideModal={this.handleClose}
            tab={this.state.tab}
            handleClick={this.handleClick}
            field={this.state.field}
            handleRadioClick={this.handleRadioClick}
            userData={this.state.userData}
            handleProgress={this.handleProgress}
          />
        ) : withScorePage && showWritingFeature ? (
          <ResumeScorePage resume={resume} handleClose={this.handleCloseWritingPage} />
        ) : (
          <Fragment>
            <GeneratorForm
              resume={resume}
              $noPadding
              updateImmue={updateImmue}
              referenceResume={referenceResume}
              isMobile={isMobile}
              trackEvent={trackEvent}
            />
          </Fragment>
        )}
      </WizardLayout>
    );
  }
}

const RenderCareerAbtest = ({
  hideModal,
  tab,
  handleClick,
  field,
  handleRadioClick,
  resume,
  userData,
  handleProgress,
}) => {
  const { t, locale } = intlHook();
  const country = getCountry();
  const renderTab = () => {
    switch (tab) {
      case 0:
        return <Tab2Sub field={field} handleClick={handleClick} />;
      case 1:
        if (field.professionHelpServices.length !== 0 || !field.professionHelpServices.includes('None'))
          return <Tab3 field={field} handleClick={handleRadioClick} handleProgress={handleProgress} />;
      case 2:
        return <Tab4 handleClick={handleClick} userData={userData} resume={resume} hideModal={hideModal} />;
    }
  };
  const renderQuestion = () => {
    switch (tab) {
      case 0:
        return t('career_question_8');
      case 1:
        if (
          ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v2')) ||
          isWithCareerLead(locale, country) ||
          ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v3')) ||
          ['with_career_lead'].includes(marvelEmitter.getActiveVariant('exp_lead_generation_v2'))
        )
          return t('career_question_9');
        return replaceString(t('career_question_5'), { CURRENCY: getCountryCode() });
      case 2:
        return t('career_question_9');
      default:
        return t('career_question_8');
    }
  };

  return (
    <Wrapper>
      <StyledWizardHeader isCaledly={tab === 2} dangerouslySetInnerHTML={{ __html: renderQuestion() }} />
      {renderTab()}
    </Wrapper>
  );
};

const StyledDiv = styled.div`
  video,
  div {
    width: 100% !important;
    height: auto !important;
  }
  .react-player__controls button[aria-label='Download'] {
    display: none;
  }
`;
const PiPButton = styled.button`
  /* Style your PiP button here */
`;

const Loader = styled.span`
  width: 68px;
  height: 68px;
  border: 9px solid #1688fe;
  border-bottom-color: #dbdbdb;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Wrapper = styled.div`
  margin-bottom: 50px;
  > iframe body {
    overflow: hidden;
  }
`;
const StyledWizardHeader = styled(WizardHeader)`
  margin-bottom: 36px;
  ${({ isCaledly }) =>
    isCaledly &&
    css`
      margin-bottom: 24px;
      color: #14141f;
      font-family: ${({ theme }) => theme.font.family.websiteBold};
      font-size: 34px;
      line-height: 44px;
    `}
  span {
    color: #1688fe;
  }
`;

const Wrap = styled.div`
  position: relative;
  ${({ theme }) =>
    theme.max('xs')`
      margin-top:30px;
    `}
`;
WizardFinalize.displayName = 'WizardFinalize';

WizardFinalize.propTypes = {
  resume: PropTypes.object,
  currentUser: PropTypes.object,
  referenceResume: PropTypes.object,
  updateImmue: PropTypes.func,
};

export default WizardFinalize;
