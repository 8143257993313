import PropTypes from 'prop-types';
import styled from 'styled-components';

import DropdownAutoSaveForFinalize from 'imports/core/ui/atoms/DropdownAutoSaveForFinalize';
import { useSelectStore } from 'zustand/SelectStore';
import useIntl from '/imports/core/api/useIntl';
import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import { UPDATE_RESUME_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import { SA_RESUME_LEFT_BAR_OPTIONS, SA_RESUME_SETTINGS, SA_RESUME_SETTINGS_V2 } from '/imports/pdf/core/api/constants';

const ResumeSettingsDropdown = (props) => {
  const { path, source, updateImmue } = props;
  const { t } = useIntl();
  const {
    id,
    saDetails,
    details: { userPic },
  } = source;
  const { label_slug, options, defaultOption } =
    SA_RESUME_SETTINGS_V2[path] || SA_RESUME_LEFT_BAR_OPTIONS[path] || SA_RESUME_SETTINGS[path];
  let filteredOptions = options;

  const selected = saDetails?.[path] || defaultOption;

  const { expanded } = useSelectStore();

  return (
    <Wrap>
      <FieldLabel summary label={t(label_slug)} />
      <DropdownAutoSaveForFinalize
        source={source}
        mutation={UPDATE_RESUME_DETAIL}
        variables={{
          docId: id,
          path: `saDetails.${path}`,
          needUpdate: true,
        }}
        value={selected}
        docType="resume"
        isExpanded={expanded === path}
        selected={selected}
        isControlled={true}
        options={filteredOptions}
        name={path}
        optimisticResponse={blockDetailImmutableUpdate(updateImmue)(id, `saDetails.${path}`)}
        {...props}
      />
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
`;

ResumeSettingsDropdown.propTypes = {
  source: PropTypes.object,
  font: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default ResumeSettingsDropdown;
