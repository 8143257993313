import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';
import Flex from '/imports/core/ui/atoms/Flex';
import CrossIcon from '/imports/generator/ui/atoms/CrossIcon';

import Modal from '/imports/core/ui/atoms/Modal';
import useTracking from '/imports/core/hooks/useTracking';
import useWindowSize from '/imports/core/api/useWindowSize';
import intlHook from 'imports/core/api/useIntl';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

const AutoApplyModal = () => {
  const { width } = useWindowSize();
  const { t } = intlHook();
  const { trackEvent } = useTracking();
  const [open, setOpen] = useState(false);

  const modalStyles = {
    modalContainer: {
      display: 'flex',
    },
    modalBackdrop: {
      backgroundColor: 'rgba(52, 60, 73, 0.8)',
    },
    modalBody: {
      width: width <= 655 ? '90%' : '610px',
      minHeight: '180px',
      maxHeight: '90%',
      height: 'auto',
      margin: 'auto',
      backgroundColor: 'var(--light-values-white)',
      borderRadius: '12px',
      boxShadow: '0 12px 48px 0 rgba(20, 20, 31, 0.24)',
      flex: 'unset',
      overflow: 'visible',
    },
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const hasModalOpened = localStorage.getItem('autoApplyModal');
    if (!hasModalOpened) {
      setOpen(true);
      localStorage.setItem('autoApplyModal', 'true');
    }
  }, []);

  return (
    <>
      <Modal
        onClose={handleClose}
        fullScreen
        styles={modalStyles}
        animation="empty"
        openStateBase
        open={open}
        timeout={0}
        disableOutsideClick={true}
      >
        <Wrapper direction="column">
          <ModalBody direction="column">
            <CrossIconWrapper onClick={handleClose}>
              <CrossIcon />
            </CrossIconWrapper>
            <Header direction="column">
              <HeadText>{t('modal_autoapply_header')}</HeadText>
              <HeadSubText>{t('modal_autoapply_content')}</HeadSubText>
            </Header>
            <ScrollWrapper direction="column"></ScrollWrapper>
          </ModalBody>
        </Wrapper>
        <ActionWrapper fullWidth justifyContent="flex-end">
          <ButtonAction onClick={handleClose}>{t('close')}</ButtonAction>
        </ActionWrapper>
      </Modal>
    </>
  );
};

export default AutoApplyModal;

const ScrollWrapper = styled(Flex)`
  gap: 24px;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular};
  }
`;
const CrossIconWrapper = styled(Flex)`
  position: absolute;
  top: -16px;
  right: -16px;
  flex-shrink: 0;
  cursor: pointer;
`;
const Header = styled(Flex)`
  gap: 12px;
`;
const HeadText = styled.span`
  color: #14141f;
  font-family: Gilroy SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
`;
const HeadSubText = styled.span`
  color: #595964;
  font-family: Gilroy Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
`;

const ActionWrapper = styled(Flex)`
  padding: 20px;
  border-top: 1px solid #ecf1f7;
  gap: 8px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
`;
const ButtonAction = styled.button`
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: var(--light-values-white);
  font-family: Gilroy SemiBold;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #1688fe;
  &:hover {
    background-color: #0d68b4;
  }
  &:active {
    background-color: #0e5eab;
  }
  ${({ $cancel }) =>
    $cancel &&
    css`
      color: #4c4c55;
      font-size: 15px;
      line-height: 22px;
      background-color: white;
      &:hover {
        color: var(--dark-values-black);
        background-color: var(--grayscale-n100);
      }
      &:active {
        background-color: var(--grayscale-n200);
      }
    `}
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: #4c4c55;
      pointer-events: none;
      background-color: #f6f6f6;
    `}
`;
const ModalBody = styled(Flex)`
  gap: 24px;
  position: relative;
`;
const Wrapper = styled(Flex)`
  padding: 32px;
`;
