export default {
  extraSmall: {
    general: {
      fontSize: 10,
      lineHeight: 1.2,
      spacing: 4,
      blockSpacing: 10,
      blockTitleFontSize: 14,
      firstBlockSpacing: 20,
      innerBlockSpacing: 12,
      innerBlockItemSpacing: 8,
      linkSpacingTop: 6,
    },
    icon: {
      height: 6,
      width: 6,
      social: {
        height: 16,
        width: 16,
      },
    },
    padding: {
      top_bottom: 4,
      right_left: 6,
      fullPage: 20,
    },
    header: {
      name: {
        fontSize: 18,
        lineHeight: 1.2,
        letterSpacing: 0.8,
      },
      title: {
        fontSize: 38,
        lineHeight: 1.2,
      },
      img: {
        height: 140,
        width: 140,
      },
    },
  },
  scale: {
    extra_small: 1,
    small: 1.1,
    big: 1.13,
    extra_big: 1.3,
  },
};
