const path = require('path');

const SUPPORTED_LANGUAGE = [
  'fr',
  'pt',
  'tr',
  'es',
  'en',
  'it',
  'id',
  'ar',
  'hu',
  'vi',
  'de',
  'pl',
  'da',
  'sv',
  'no',
  'fi',
  'ka',
  'nl',
  'cs',
  'ru',
  'el',
  'th',
  'lv',
  'pt-br',
  'ro',
  'lt',
  'he',
  'ms',
  'et',
  'hr',
  'sr',
  'sl',
  'ja',
  'ko',
  'zh-cn',
  'tj',
  'az',
  'ca',
  'kk',
  'hi',
  'bn',
  'uz',
  'bg',
  'tl',
  'uk',
  'fa',
];

const internalPrefixes = [/^\/_next\//, /^\/static\//];

function isInternalUrl(url) {
  for (const prefix of internalPrefixes) {
    if (prefix.test(url)) {
      return true;
    }
  }

  return false;
}

function isFontOrImageUrl(url) {
  return /^\/static\/(images|fonts)\//.test(url);
}

const getDefaultLanguage = (domain) => {
  switch (domain) {
    case 'cvdeboss':
      return 'fr';
    default:
      return 'en';
  }
};

const getDomainFromHost = (url) => {
  switch (true) {
    case url.toLocaleLowerCase().includes('cv-olustur'):
      return 'cv-olustur';
    case url.toLocaleLowerCase().includes('bluepill'):
      return 'bluepill';
    case url.toLocaleLowerCase().includes('applycator'):
      return 'applycator';
    case url.toLocaleLowerCase().includes('autoapply'):
      return 'autoapply';
    case url.toLocaleLowerCase().includes('resumetiger'):
      return 'resumetiger';
    case url.toLocaleLowerCase().includes('cvelevator'):
      return 'cvelevator';
    case url.toLocaleLowerCase().includes('quantumresume'):
      return 'quantumresume';
    case url.toLocaleLowerCase().includes('cvenminutos'):
      return 'cvenminutos';
    case url.toLocaleLowerCase().includes('dreamresume'):
      return 'dreamresume';
    case url.toLocaleLowerCase().includes('melhorcv'):
      return 'melhorcv';
    case url.toLocaleLowerCase().includes('atelierdecv'):
      return 'atelierdecv';
    case url.toLocaleLowerCase().includes('cvexito'):
      return 'cvexito';
    case url.toLocaleLowerCase().includes('verycoolcv'):
      return 'verycoolcv';
    case url.toLocaleLowerCase().includes('kazakhstan-cv'):
      return 'kazakhstan-cv';
    case url.toLocaleLowerCase().includes('cvyarat'):
      return 'cvyarat';
    case url.toLocaleLowerCase().includes('kreatorcv'):
      return 'kreatorcv';
    case url.toLocaleLowerCase().includes('zivotopis-primjer'):
      return 'zivotopis-primjer';
    case url.toLocaleLowerCase().includes('cvkoostamine'):
      return 'cvkoostamine';
    case url.toLocaleLowerCase().includes('theresumeranger'):
      return 'theresumeranger';
    case url.toLocaleLowerCase().includes('resumizeme'):
      return 'resumizeme';
    case url.toLowerCase().includes('cvtoolspro'):
      return 'cvtoolspro';
    case url.toLowerCase().includes('cv-lite'):
      return 'cv-lite';
    case url.toLowerCase().includes('contoh-resume'):
      return 'contoh-resume';
    case url.toLowerCase().includes('cv-hebrew'):
      return 'cv-hebrew';
    case url.toLowerCase().includes('cv-pavyzdys'):
      return 'cv-pavyzdys';
    case url.toLowerCase().includes('creare-cv'):
      return 'creare-cv';
    case url.toLowerCase().includes('modelos-de-curriculo'):
      return 'modelos-de-curriculo';
    case url.toLowerCase().includes('cvparaugs'):
      return 'cvparaugs';
    case url.toLowerCase().includes('cv-in-arabic'):
      return 'cv-in-arabic';
    case url.toLowerCase().includes('thai-resume'):
      return 'thai-resume';
    case url.toLowerCase().includes('criarcv'):
      return 'criarcv';
    case url.toLowerCase().includes('brillantcv'):
      return 'brillantcv';
    case url.toLowerCase().includes('resume-nation'):
      return 'resume-nation';
    case url.toLowerCase().includes('cveksempel'):
      return 'cveksempel';
    case url.toLowerCase().includes('cvforma'):
      return 'cvforma';
    case url.toLowerCase().includes('cvhazirlama'):
      return 'cvhazirlama';
    case url.toLowerCase().includes('cvminta'):
      return 'cvminta';
    case url.toLowerCase().includes('cv-pohja'):
      return 'cv-pohja';
    case url.toLowerCase().includes('cv-skabelon'):
      return 'cv-skabelon';
    case url.toLowerCase().includes('cv-voorbeeld'):
      return 'cv-voorbeeld';
    case url.toLowerCase().includes('cv-wzor'):
      return 'cv-wzor';
    case url.toLowerCase().includes('cvmenarik'):
      return 'cvmenarik';
    case url.toLowerCase().includes('cvvzor'):
      return 'cvvzor';
    case url.toLowerCase().includes('xn--mxabdxcg5aeo5c'):
      return 'xn--mxabdxcg5aeo5c';
    case url.toLowerCase().includes('xn--e1aaaggwcwefd4n'):
      return 'xn--e1aaaggwcwefd4n';
    case url.toLowerCase().includes('lebenslaufschreiben'):
      return 'lebenslaufschreiben';
    case url.toLowerCase().includes('mau-cv'):
      return 'mau-cv';
    case url.toLowerCase().includes('modellocv'):
      return 'modellocv';
    case url.toLowerCase().includes('skriva-cv'):
      return 'skriva-cv';
    case url.toLowerCase().includes('cvdeboss'):
      return 'cvdeboss';
    case url.toLowerCase().includes('mysmartcv'):
      return 'mysmartcv';
    case url.toLowerCase().includes('buildmycv'):
      return 'buildmycv';
    case url.toLowerCase().includes('curriculumlisto'):
      return 'curriculumlisto';
    case url.toLowerCase().includes('modeles-cv'):
      return 'modeles-cv';
    case url.toLowerCase().includes('resume-example'):
      return 'resume-example';
    case url.toLowerCase().includes('ejemplos-curriculum'):
      return 'ejemplos-curriculum';
    case url.toLowerCase().includes('bestonlineresume'):
      return 'bestonlineresume';
    case url.toLowerCase().includes('elegantcv'):
      return 'elegantcv';
    case url.toLowerCase().includes('resumedone'):
    default:
      return 'resumedone';
  }
};

const getSiteFromDomain = (domain = 'resumedone') => {
  switch (true) {
    case domain.toLocaleLowerCase().includes('cv-olustur'):
      return 'https://cv-olustur.com';
    case domain.toLocaleLowerCase().includes('bluepill'):
      return 'https://bluepill.now';
    case domain.toLocaleLowerCase().includes('applycator'):
      return 'https://applycator.ai';
    case domain.toLocaleLowerCase().includes('autoapply'):
      return 'https://autoapply.tech';
    case domain.toLocaleLowerCase().includes('resumetiger'):
      return 'https://resumetiger.app';
    case domain.toLocaleLowerCase().includes('cvelevator'):
      return 'https://cvelevator.app';
    case domain.toLocaleLowerCase().includes('quantumresume'):
      return 'https://quantumresume.app';
    case domain.toLocaleLowerCase().includes('cvenminutos'):
      return 'https://cvenminutos.com';
    case domain.toLocaleLowerCase().includes('dreamresume'):
      return 'https://dreamresume.app';
    case domain.toLocaleLowerCase().includes('melhorcv'):
      return 'https://melhorcv.com';
    case domain.toLocaleLowerCase().includes('atelierdecv'):
      return 'https://atelierdecv.com';
    case domain.toLocaleLowerCase().includes('cvexito'):
      return 'https://cvexito.com';
    case domain.toLocaleLowerCase().includes('kazakhstan-cv'):
      return 'https://kazakhstan-cv.com';
    case domain.toLocaleLowerCase().includes('cvyarat'):
      return 'https://cvyarat.app';
    case domain.toLocaleLowerCase().includes('kreatorcv'):
      return 'https://www.kreatorcv.com';
    case domain.toLocaleLowerCase().includes('zivotopis-primjer'):
      return 'https://www.zivotopis-primjer.com';
    case domain.toLocaleLowerCase().includes('cvkoostamine'):
      return 'https://cvkoostamine.com';
    case domain.toLocaleLowerCase().includes('theresumeranger'):
      return 'https://theresumeranger.com';
    case domain.toLocaleLowerCase().includes('resumizeme'):
      return 'https://resumizeme.com';
    case domain.toLocaleLowerCase().includes('cvtoolspro'):
      return 'https://cvtoolspro.com';
    case domain.toLowerCase().includes('cv-lite'):
      return 'https://cv-lite.com';
    case domain.toLowerCase().includes('cvdeboss'):
      return 'https://cvdeboss.com';
    case domain.toLowerCase().includes('mysmartcv'):
      return 'https://mysmartcv.co';
    case domain.toLowerCase().includes('bestonlineresume'):
      return 'https://bestonlineresume.com';
    case domain.toLowerCase().includes('buildmycv'):
      return 'https://buildmycv.io';
    case domain.toLowerCase().includes('elegantcv'):
      return 'https://elegantcv.app';
    case domain.toLowerCase().includes('brillantcv'):
      return 'https://brillantcv.com';
    case domain.toLowerCase().includes('resume-nation'):
      return 'https://resume-nation.com';
    case domain.toLocaleLowerCase().includes('ejemplos-curriculum'):
      return 'https://ejemplos-curriculum.com';
    case domain.toLocaleLowerCase().includes('cv-hebrew'):
      return 'https://cv-hebrew.com';
    case domain.toLocaleLowerCase().includes('cv-pavyzdys'):
      return 'https://cv-pavyzdys.com';
    case domain.toLocaleLowerCase().includes('creare-cv'):
      return 'https://creare-cv.com';
    case domain.toLocaleLowerCase().includes('modelos-de-curriculo'):
      return 'https://modelos-de-curriculo.com';
    case domain.toLocaleLowerCase().includes('cvparaugs'):
      return 'https://cvparaugs.com';
    case domain.toLocaleLowerCase().includes('cv-in-arabic'):
      return 'https://cv-in-arabic.com';
    case domain.toLocaleLowerCase().includes('thai-resume'):
      return 'https://thai-resume.com';
    case domain.toLocaleLowerCase().includes('criarcv'):
      return 'https://criarcv.com';
    case domain.toLocaleLowerCase().includes('cveksempel'):
      return 'https://cveksempel.com';
    case domain.toLocaleLowerCase().includes('cvforma'):
      return 'https://cvforma.com';
    case domain.toLocaleLowerCase().includes('cvminta'):
      return 'https://cvminta.com';
    case domain.toLocaleLowerCase().includes('cv-pohja'):
      return 'https://cv-pohja.com';
    case domain.toLocaleLowerCase().includes('cv-skabelon'):
      return 'https://cv-skabelon.com';
    case domain.toLocaleLowerCase().includes('cv-voorbeeld'):
      return 'https://cv-voorbeeld.com';
    case domain.toLocaleLowerCase().includes('cv-wzor'):
      return 'https://cv-wzor.com';
    case domain.toLocaleLowerCase().includes('cvmenarik'):
      return 'https://cvmenarik.com';
    case domain.toLocaleLowerCase().includes('cvvzor'):
      return 'https://cvvzor.com';
    case domain.toLocaleLowerCase().includes('xn--mxabdxcg5aeo5c'):
      return 'https://xn--mxabdxcg5aeo5c.com';
    case domain.toLocaleLowerCase().includes('xn--e1aaaggwcwefd4n'):
      return 'https://xn--e1aaaggwcwefd4n.com';
    case domain.toLocaleLowerCase().includes('lebenslaufschreiben'):
      return 'https://lebenslaufschreiben.com';
    case domain.toLocaleLowerCase().includes('mau-cv'):
      return 'https://mau-cv.com';
    case domain.toLocaleLowerCase().includes('modellocv'):
      return 'https://modellocv.com';
    case domain.toLocaleLowerCase().includes('skriva-cv'):
      return 'https://skriva-cv.com';
    case domain.toLocaleLowerCase().includes('curriculumlisto'):
      return 'https://curriculumlisto.com';
    case domain.toLocaleLowerCase().includes('resume-example'):
      return 'https://resume-example.com';
    case domain.toLocaleLowerCase().includes('contoh-resume'):
      return 'https://contoh-resume.com';
    case domain.toLocaleLowerCase().includes('cvhazirlama'):
      return 'https://cvhazirlama.cool';
    case domain.toLocaleLowerCase().includes('modeles-cv'):
      return 'https://www.modeles-cv.fr';
    case domain.toLowerCase().includes('resumedone'):
    default:
      return 'https://resumedone.co';
  }
};

const getSitemMapOptions = (host) => {
  return {
    root: path.join(__dirname, `../public/${host}`),
    headers: {
      'Content-Type': 'text/xml;charset=UTF-8',
    },
  };
};

const getRobotOptions = (host) => {
  return {
    root: path.join(__dirname, `../public/${host}`),
    headers: {
      'Content-Type': 'text/plain;charset=UTF-8',
    },
  };
};

const getAppleVerification = (host) => {
  return {
    root: path.join(__dirname, `../public/${host}`),
    headers: {
      'Content-Type': 'text/plain;charset=UTF-8',
    },
  };
};

module.exports = {
  isInternalUrl,
  SUPPORTED_LANGUAGE,
  getDefaultLanguage,
  isFontOrImageUrl,
  getDomainFromHost,
  getSiteFromDomain,
  getSitemMapOptions,
  getRobotOptions,
  getAppleVerification,
};
