import { withTracking } from 'imports/core/hooks/useTracking';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import DropdownForAutosave from '/imports/core/ui/atoms/DropdownForAutosave';
import Autosave from '/imports/core/ui/components/Autosave';

@withTracking
class DropdownAutoSaveForFinalize extends PureComponent {
  static propTypes = {
    options: PropTypes.array,
    dark: PropTypes.bool,
    stripe: PropTypes.bool,
    scrollOnKeyPress: PropTypes.bool,
    trackEvent: PropTypes.func,
    onChangeCallback: PropTypes.func,
    value: PropTypes.string,
    preview: PropTypes.any,
    customLabel: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    expandTitle: PropTypes.string,
    source: PropTypes.object,
    isExpanded: PropTypes.bool,
  };

  onChangeCallback = (onChange) => (e) => {
    const { name, value } = e.target;
    const { trackEvent, source } = this.props;
    if (name === 'arabicHeadingFont') {
      trackEvent('arabic_heading_font');
    }
    if (name === 'arabicContentFont') {
      trackEvent('arabic_content_font');
    }
    if (name === 'gender') {
      trackEvent('gender', { gender: value });
    }
    if (name === 'sideBarContentLayout' && value === 'elegant') {
      const { blocks } = source || {};
      const skillBlock = blocks?.find((block) => block.type === 'SKILLS');
      if (skillBlock?.hideLevel === false) {
        const hideLevelToggle = document.getElementsByName('interactive-element-hideLevel')[0];
        if (hideLevelToggle) {
          hideLevelToggle?.click();
        }
      }
    }

    onChange(e);
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(e);
    }
  };

  render() {
    const { options, dark, stripe, value, preview, customLabel, placeholder, name, expandTitle, isExpanded, source } =
      this.props;

    return (
      <Autosave {...this.props}>
        {({ onChange }) => (
          <DropdownForAutosave
            {...this.props}
            onChange={this.onChangeCallback(onChange)}
            options={options}
            defaultSelected={value || ''}
            dark={dark}
            preview={preview}
            stripe={stripe}
            customLabel={customLabel}
            placeholder={placeholder}
            name={name}
            expandTitle={expandTitle}
            isExpanded={isExpanded}
            source={source}
          />
        )}
      </Autosave>
    );
  }
}

export default DropdownAutoSaveForFinalize;
